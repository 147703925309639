const myACL = [
  {
    resource: 'all',
    action: 'manage'
  },
  {
    resource: '',
    action: 'Jasa OPS Pengguna'
  },
  {
    resource: '',
    action: 'Jasa OPS Admin'
  },
  {
    resource: '',
    action: 'Jasa OPS Viewer'
  }
]
export default [
  {
    header: 'PELAYANAN',
    acl: myACL
  },
  {
    title: 'Jasa Operasional',
    icon: 'BookOpenIcon',
    // tag: 'KG',
    tagVariant: 'light-primary',
    acl: myACL,
    children: [
      {
        key: 'Pengajuan',
        title: 'Pengajuan',
        icon: 'SendIcon',
        route: 'pelayanan-jasa-operasional-pengajuan',
        // tag: '',
        tagVariant: 'warning',
        acl: myACL
      }
      // {
      //   key: 'Pengajuan Inprogress',
      //   title: 'In Progress',
      //   icon: 'CalendarIcon',
      //   route: 'pelayanan-jasa-operasional-pengajuan-in-progress',
      //   tag: 'proses',
      //   tagVariant: 'info',
      //   acl: myACL
      // },
      // {
      //   key: 'Pengajuan Selesai',
      //   title: 'Selesai',
      //   icon: 'PrinterIcon',
      //   route: 'pelayanan-jasa-operasional-pengajuan-selesai',
      //   tag: 'JR',
      //   tagVariant: 'success',
      //   acl: myACL
      // },
      // {
      //   key: 'Pengajuan Revisi',
      //   title: 'Pengajuan',
      //   icon: 'CalendarIcon',
      //   route: 'pelayanan-jasa-operasional-pengajuan-revisi',
      //   tag: 'Revisi',
      //   tagVariant: 'warning',
      //   acl: myACL
      // }
    ]
  },
  {
    title: 'Digital Signature',
    icon: 'SlackIcon',
    // tag: 'KG',
    tagVariant: 'light-primary',
    acl: myACL,
    children: [
      {
        key: 'Daily Timesheet',
        title: 'Daily Timesheet',
        icon: 'CalendarIcon',
        route: 'pelayanan-jasa-operasional-daily-timesheet',
        // tag: 'DT',
        tagVariant: 'warning',
        acl: myACL
      },
      {
        key: 'Document Requisition',
        title: 'Documents',
        icon: 'PrinterIcon',
        route: 'pelayanan-jasa-operasional-document-requisition',
        tag: 'JR',
        tagVariant: 'success',
        acl: myACL
      }
    ]
  }
]
