/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import navigationClient from './navigation_client'
import navigationSuperadmin from './navigation_superadmin'
import navigationBank from './navigation_bank'
import navigationCompanyOwn from './navigation_company_own'
// import navigationSlb from './navigation_slb'
import navigationHelper from './navigation_helper'
import navigation_host from './navigation_host'
import navigation_data_master_river from './navigation_data_master_river'
import navigation_reporting from './navigation_reporting'
import navigation_client_river1 from './navigation_client_river1'
import navigation_client_river2 from './navigation_client_river2'
import navigation_client_river3 from './navigation_client_river3'
import navigation_kegiatan from './navigation_kegiatan'
import navigation_jasa from './navigation_jasa'

// Array of sections
export default [
  ...navigationSuperadmin,
  ...navigation_jasa,
  ...navigationClient,
  ...navigation_client_river1,
  ...navigation_kegiatan,
  ...navigation_client_river2,
  ...navigation_client_river3,
  ...navigation_reporting,
  ...navigationBank,
  ...navigation_data_master_river /* soon */,
  ...navigationCompanyOwn,
  ...navigation_host /* soon */,
  ...navigationHelper
]
